<template>
  <b-container>
    <b-form>
      <b-card bg-variant="light" :title="$t('import.headline')" footer-class="text-right" footer-bg-variant="light">
        <b-form-group id="fieldset-1" label-for="csv-" :label="$t('import.file')" label-cols="4" label-cols-lg="3">
          <b-form-file
            id="input-fileupload"
            required
            accept="text/csv"
            autofocus
            :placeholder="$t('import.upload-placeholder')"
            :drop-placeholder="$t('import.upload-drop-placeholder')"
            v-model="csvFile"
            :disabled="formSubmitted"
          />
        </b-form-group>

        <b-form-group
          id="fieldset-2"
          label-for="input-reviewUser"
          :label="$t('import.reviewUser')"
          label-cols="4"
          label-cols-lg="3"
        >
          <vue-typeahead-bootstrap
            id="employeeSearch"
            class="flex-fill"
            :placeholder="$t('validation.search-user')"
            :showOnFocus="true"
            :data="allUsers"
            :serializer="user => user.fullName"
            @hit="reviewUser = $event"
            v-model="reviewUserQuery"
            :disabled="formSubmitted"
          />
        </b-form-group>

        <b-form-group
          id="fieldset-3"
          label-for="input-reviewComment"
          :label="$t('import.reviewComment')"
          label-cols="4"
          label-cols-lg="3"
        >
          <b-form-input
            id="input-reviewComment"
            trim
            maxlength="255"
            v-model="reviewComment"
            :disabled="formSubmitted"
          />
        </b-form-group>

        <b-progress v-if="formSubmitted" :max="100" class="mt-5">
          <b-progress-bar
            :value="progressbarValues.upload"
            :variant="uploadFinished ? 'success' : 'info'"
            :animated="!uploadFinished"
            class="mb-4"
            label="Upload"
          />
          <b-progress-bar
            v-if="uploadFinished"
            :value="progressbarValues.import"
            :variant="importProgressbarColor"
            :animated="apiImportResult === null && apiErrorObj === null"
            class="mb-4"
            label="Import"
          />
        </b-progress>

        <b-alert v-if="apiErrorObj" variant="danger" show class="mt-4">{{ apiErrorObj.message }}</b-alert>

        <template #footer>
          <b-button @click="uploadCsv()" variant="primary" :disabled="formSubmitted">{{
            $t('import.submit')
          }}</b-button>
        </template>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import { UserUtil } from '@/util/UserUtil'

export default {
  name: 'ManageImport',
  mixins: [ApiMixin, NotificationMixin],
  components: { VueTypeaheadBootstrap },
  data() {
    return {
      allUsers: [],
      csvFile: null,
      reviewUserQuery: '',
      reviewUser: null,
      reviewComment: '',
      formSubmitted: false,
      apiErrorObj: null,
      apiImportResult: null,
      progressbarValues: {
        upload: 0,
        import: 0
      },
      progressbarStages: {
        upload: 20,
        import: 80
      }
    }
  },
  watch: {
    uploadFinished: function (newUploadFinished) {
      if (newUploadFinished === true) {
        this.progressbarValues.import = this.progressbarStages.import
      }
    }
  },
  computed: {
    importSuccessNoErrors() {
      return this.apiImportResult !== null && this.apiImportResult.importedLinesCount > 0
    },
    importSuccessWithErrors() {
      return this.apiImportResult !== null && this.apiImportResult.failedLinesCount > 0
    },
    uploadFinished() {
      return Math.round(this.progressbarValues.upload) === this.progressbarStages.upload
    },
    importProgressbarColor() {
      if (this.importSuccessWithErrors) {
        return 'warning'
      } else if (this.importSuccessNoErrors) {
        return 'success'
      } else if (this.apiErrorObj !== null) {
        return 'danger'
      } else {
        return 'info'
      }
    }
  },
  mounted() {
    this.getAllActiveUsers()
  },
  methods: {
    getAllActiveUsers() {
      let self = this
      this.getRequest(
        '/users?status=active',
        new RequestConfig().onSuccess(res => {
          self.allUsers = UserUtil.sortByFullName(res.data)
        })
      )
    },
    uploadCsv() {
      this.formSubmitted = true
      this.apiErrorObj = null

      const form = new FormData()
      form.append('file', this.csvFile)
      form.append('review_user', this.reviewUser.userName)
      form.append('review_comment', this.reviewComment)

      let self = this
      this.postRequest(
        '/import',
        form,
        new RequestConfig()
          .withAxiosConfig({
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function (progressEvent) {
              self.progressbarValues.upload =
                Math.round((progressEvent.loaded * 100) / progressEvent.total) * (self.progressbarStages.upload / 100)
            }
          })
          .onSuccess(res => {
            self.apiImportResult = res.data
            self.uploadProgressbarValue = self.progressbarStages.import
            return res
          })
          .onError(err => {
            self.apiErrorObj = err.response.data
            self.formSubmitted = false
          })
      ).then(res => {
        if (res.status === 200 && res.data.importId) {
          setTimeout(() => {
            this.$router.push({
              name: 'manage_import_errors',
              params: {
                id: this.apiImportResult.importId,
                importResult: this.apiImportResult
              }
            })
          }, 1500)
        } else if (!res.data.importId) {
          self.apiErrorObj = { message: self.$t('import.importNoEntries') }
          self.formSubmitted = false
        } else {
          self.apiErrorObj = { message: 'Unknown Error!' }
          self.formSubmitted = false
        }
      })
    }
  }
}
</script>

<style>
.progress-bar {
  height: 1rem;
}
</style>
