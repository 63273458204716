<template>
  <b-container fluid="xxl">
    <b-card bg-variant="light" :title="$t('import.headline-results')" class="mb-5">
      <b-alert v-if="importSuccessCount > 0" variant="success" show class="mt-4">{{
        $t('import.importSuccess', { importCount: importSuccessCount })
      }}</b-alert>
      <b-alert v-if="importErrorCount > 0" variant="warning" show class="mt-4">{{
        $t('import.importErrors', { errorCount: importErrorCount })
      }}</b-alert>
    </b-card>

    <b-card
      v-if="importErrorCount > 0"
      bg-variant="light"
      :title="$t('import.headline-errors')"
      footer-class="text-right"
      footer-bg-variant="light"
    >
      <div id="headContainer" class="p-0 mt-4 mx-0 mb-3 text-right">
        <b-button size="sm" @click="selectAllRows()" :disabled="tableBusy">{{ $t('import.select-all') }}</b-button>
        <b-button size="sm" @click="clearSelected()" :disabled="tableBusy" class="ml-2">{{
          $t('import.clear-selected')
        }}</b-button>
      </div>
      <b-table
        id="importErrors-datatable"
        ref="importErrorsDatatable"
        head-variant="light"
        show-empty
        small
        fixed
        responsive
        stacked="lg"
        selectable
        select-mode="range"
        @row-selected="onRowSelected"
        hover="hover"
        :fields="fields"
        :items="importErrors"
        @refreshed="selectAllRows()"
        :busy.sync="tableBusy"
      >
        <template #table-colgroup="scope">
          <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.width }" />
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-2" />
            <strong>{{ $t('general.table.loading') }}</strong>
          </div>
        </template>
        <template #cell()="row">
          <ImportErrorInput
            :value.sync="row.item[row.field.key]"
            :original-value="row.value"
            :violation-message="row.item.violationMap[row.field.key]"
          />
        </template>
        <template #cell(checkbox)="row">
          <b-checkbox :checked="row.rowSelected" @change="row.rowSelected ? row.unselectRow() : row.selectRow()" />
        </template>
      </b-table>

      <template #footer>
        <b-alert :show="hasUnselectedRows" variant="warning" class="my-0 mr-3 py-1 px-2 import-warning">{{
          $t('import.import-warning', { unselectedRowCount: importErrors.length - selected.length })
        }}</b-alert>
        <b-button @click="save()" :variant="hasUnselectedRows ? 'danger' : 'primary'" :disabled="tableBusy">{{
          $t('import.submit')
        }}</b-button>
      </template>
    </b-card>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import ImportErrorInput from '@/components/import/ImportErrorInput'

export default {
  name: 'ImportErrors',
  mixins: [ApiMixin, NotificationMixin],
  components: { ImportErrorInput },
  data() {
    return {
      importSuccessCount: 0,
      importErrorCount: 0,
      importErrors: [],
      selected: [],
      tableBusy: true,
      fields: [
        { key: 'checkbox', label: '', sortable: false, width: '25px' },
        { key: 'date', label: this.$t('import.table-headers.date'), sortable: true, width: '95px' },
        { key: 'startTime', label: this.$t('import.table-headers.startTime'), sortable: false, width: '60px' },
        { key: 'endTime', label: this.$t('import.table-headers.endTime'), sortable: false, width: '55px' },
        { key: 'breakTime', label: this.$t('import.table-headers.breakTime'), sortable: false, width: '55px' },
        { key: 'baseDuration', label: this.$t('import.table-headers.baseDuration'), sortable: false, width: '55px' },
        { key: 'timefactor', label: this.$t('import.table-headers.timefactor'), sortable: false, width: '55px' },
        { key: 'duration', label: this.$t('import.table-headers.duration'), sortable: false, width: '55px' },
        { key: 'person', label: this.$t('import.table-headers.person'), sortable: true, width: '8%' },
        { key: 'comment', label: this.$t('import.table-headers.comment'), sortable: false, width: 'auto' },
        { key: 'ticketName', label: this.$t('import.table-headers.ticketName'), sortable: true, width: 'auto' },
        {
          key: 'parentTicketName',
          label: this.$t('import.table-headers.parentTicketName'),
          sortable: true,
          width: 'auto'
        },
        { key: 'project', label: this.$t('import.table-headers.project'), sortable: true, width: '12%' },
        { key: 'customer', label: this.$t('import.table-headers.customer'), sortable: true, width: '10%' }
      ]
    }
  },
  computed: {
    hasUnselectedRows() {
      return this.selected.length < this.importErrors.length
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.countImportItems(this.$route.params.id, 'success')
      this.countImportItems(this.$route.params.id, 'errors')
      this.fetchImportErrors(this.$route.params.id)
    },
    countImportItems(importId, itemType) {
      let self = this
      this.getRequest(
        '/import/' + importId + '/' + itemType + '/size',
        new RequestConfig()
          .onSuccess(res => {
            if (itemType === 'errors') {
              self.importErrorCount = res.data
            } else if (itemType === 'success') {
              self.importSuccessCount = res.data
            }
          })
          .onError(err => {
            console.log(err)
          })
      )
    },
    fetchImportErrors(id) {
      this.tableBusy = true
      let self = this
      this.getRequest(
        '/import/' + id + '/errors',
        new RequestConfig()
          .onSuccess(res => {
            self.importErrors = res.data

            // save violations as map for better
            for (let i = 0; i < self.importErrors.length; i++) {
              let row = self.importErrors[i]
              row.violationMap = {}
              if (Object.prototype.hasOwnProperty.call(row, 'violations')) {
                for (let j = 0; j < row.violations.length; j++) {
                  let violation = row.violations[j]
                  row.violationMap[violation.field] = violation.message
                }
              }
            }
          })
          .onError(err => {
            console.log(err)
          })
      ).then(() => {
        if (this.importErrors.length > 0) {
          this.tableBusy = false
          this.selectAllRows()
        }
      })
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.importErrorsDatatable.selectAllRows()
    },
    clearSelected() {
      this.$refs.importErrorsDatatable.clearSelected()
    },
    save() {
      this.tableBusy = true
      let self = this
      this.postRequest(
        '/import/' + this.$route.params.id + '/errors/save',
        this.selected,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess('Import successful')
          self.init()
        })
      )
    }
  }
}
</script>

<style lang="scss">
#importErrors-datatable.table.b-table-selectable {
  tr[aria-selected='false'] > td:not(:first-child) {
    opacity: 0.4;
  }
  .b-table-row-selected {
    background-color: inherit !important;
  }
}

div.import-warning {
  display: inline-block;
}
</style>
